<template>
  <div class="ledgerDataSummarization">
    <div>
      <el-form
        ref="searchRef"
        :model="searchForm"
        label-width="100px"
        size="small"
      >
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="签约主体">
              <el-select
                style="width: 100%"
                v-model="searchForm.entityId"
                :remote-method="entityRemoteMethod"
                :loading="entityLoading"
                placeholder="请输入关键词"
                filterable
                remote
                clearable
                @change="initData"
                @clear="onEntityClear"
              >
                <el-option
                  v-for="item in entityList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="一级商家">
              <el-select
                style="width: 100%"
                v-model="searchForm.seniorId"
                :remote-method="seniorRemoteMethod"
                :loading="seniorLoading"
                placeholder="请输入关键词"
                clearable
                filterable
                remote
                @change="initData"
                @clear="onSeniorClear"
              >
                <el-option
                  v-for="item in seniorList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="二级商家">
              <el-select
                style="width: 100%"
                v-model="searchForm.businessId"
                :remote-method="businessRemoteMethod"
                :loading="businessLoading"
                placeholder="请输入关键词"
                filterable
                remote
                clearable
                @change="initData"
                @clear="onBusinessClear"
              >
                <el-option
                  v-for="item in businessList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="日期">
              <el-date-picker
                style="width: 100%"
                v-model="collect_date"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="setDateRange"
                @change="onDateChange"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <div>
            <el-button type="primary" size="small" @click="initData"
              >查询</el-button
            >
          </div>
        </el-row>
      </el-form>
    </div>
    <div class="auth-table">
      <el-table :data="tableData" header-cell-class-name="table_header" border v-loading="loading"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, .6)">
        <el-table-column prop="business_ledger_amount" label="商家分账总额" />
        <el-table-column prop="business_ledger_principal" label="本金" />
        <el-table-column prop="business_ledger_interest" label="商家管理费" />
        <el-table-column prop="business_payer_interest" label="商家贴息">
          <template slot-scope="scope">
            <template v-if="Number(scope.row.business_payer_interest) > 0">
              -{{ scope.row.business_payer_interest }}
            </template>
            <template v-else>
              {{ scope.row.business_payer_interest }}
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="business_ledger_refund" label="退款手续费" />
        <el-table-column
          prop="remain_principal_amount"
          label="当前未还本金总额"
        />
      </el-table>
    </div>
  </div>
</template>

<script>
import { selectBusiness, selectEntity, selectSenior } from "@/api/selectfun.js";
import {
  businessLedgerSummaryDetail,
  remainPrincipalSummaryAmount,
} from "@/api/dataSummarization.js";
export default {
  name: "ledgerDataSummarization",
  data() {
    return {
      loading:false,
      tableData: [],
      searchForm: {
        seniorId: "",
        entityId: "",
        businessId: "",
        startTime: "",
        endTime: "",
      },
      seniorList: [],
      seniorLoading: false,
      entityList: [],
      entityLoading: false,
      businessList: [],
      businessLoading: false,
      collect_date: "",
      setDateRange: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
    };
  },

  mounted() {
    this.setSelectTime();
    this.initData();
  },

  methods: {
    async initData() {
      this.loading = true
      const { code, data } = await businessLedgerSummaryDetail(this.searchForm);
      this.loading = false

      if (code == 200) {
        var remainData = await this.getRemainPrincipalSummaryAmount();
        this.tableData = data.map((item) => {
          item.remain_principal_amount = remainData[0].remain_principal_amount;
          return item;
        });
      }
    },
    async getRemainPrincipalSummaryAmount() {
      const { code, data } = await remainPrincipalSummaryAmount(
        this.searchForm
      );
      if (code == 200) {
        return data;
      }
    },
    businessRemoteMethod(query) {
      if (query != "") {
        this.businessLoading = true;
        setTimeout(() => {
          this.businessLoading = false;
          this.getSelectBusiness(query);
        }, 200);
      } else {
        this.businessList = [];
      }
    },
    onBusinessClear() {
      this.searchForm.businessId = "";
      this.businessList = [];
    },
    async getSelectBusiness(query) {
      const param = {
        business_name: query,
      };
      const { code, data } = await selectBusiness(param);
      if (code == 200) {
        this.businessList = data.map((item) => {
          item.value = item.business_id;
          item.label = item.full_name;
          return item;
        });
      }
    },
    entityRemoteMethod(query) {
      if (query != "") {
        this.entityLoading = true;
        setTimeout(() => {
          this.entityLoading = false;
          this.getSelectEntity(query);
        }, 200);
      } else {
        this.entityList = [];
      }
    },
    onEntityClear() {
      this.searchForm.entityId = "";
      this.entityList = [];
    },
    async getSelectEntity(query) {
      const param = {
        entity_name: query,
      };
      const { code, data } = await selectEntity(param);
      if (code == 200) {
        this.entityList = data.map((item) => {
          item.value = item.entity_id;
          item.label = item.entity_name;
          return item;
        });
      }
    },
    seniorRemoteMethod(query) {
      if (query != "") {
        this.seniorLoading = true;
        setTimeout(() => {
          this.seniorLoading = false;
          this.getSelectSenior(query);
        }, 200);
      } else {
        this.seniorList = [];
      }
    },
    onSeniorClear() {
      this.searchForm.seniorId = "";
      this.seniorList = [];
    },
    async getSelectSenior(query) {
      const param = {
        senior_name: query,
      };
      const { code, data } = await selectSenior(param);
      if (code == 200) {
        this.seniorList = data.map((item) => {
          item.value = item.senior_id;
          item.label = item.senior_name;
          return item;
        });
      }
    },
    setSelectTime() {
      var _this = this;
      var date = new Date();
      var y = date.getFullYear();
      var m =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

      var start_day = y + "-" + m + "-" + "01";
      var end_day = `${y}-${m}-${d}`;

      _this.collect_date = [start_day, end_day];
      this.searchForm.startTime = start_day + " " + "00:00:00";
      this.searchForm.endTime = end_day + " " + "23:59:59";
    },
    onDateChange(val) {
      if (val) {
        this.searchForm.startTime = val[0] + " " + "00:00:00";
        this.searchForm.endTime = val[1] + " " + "23:59:59";
      } else {
        this.searchForm.startTime = "";
        this.searchForm.endTime = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ledgerDataSummarization {
  .auth-table {
    /deep/ .table_header {
      background-color: #f8f8f9 !important;
      color: #515a6e !important;
    }
  }
}
</style>